import { Inject } from 'inversify-props';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import {
  IPDC_PRODUCT_SERVICE,
  IPDC_PRODUCT_THEME_SERVICE,
  IpdcProductService,
  IpdcProductThemeService,
} from '@/services/ipdc';
import { IpdcProduct, IpdcProductTheme } from '@govflanders/mbp-admin-panel-shared';

@Module({ namespaced: true })
export class IpdcProductModule extends VuexModule {
  @Inject(IPDC_PRODUCT_SERVICE)
  private ipdcProductService!: IpdcProductService;

  @Inject(IPDC_PRODUCT_THEME_SERVICE)
  private _ipdcProductThemeService!: IpdcProductThemeService;
  private _themeOptions: IpdcProductTheme[] = [];
  private _themeOptionsLoading = false;

  public get themeOptions() {
    return this._themeOptions;
  }

  public get themeOptionsLoading() {
    return this._themeOptionsLoading;
  }

  @Action
  public async update(
    product: IpdcProduct,
  ): Promise<{ success: boolean; obj?: IpdcProduct | number }> {
    return this.ipdcProductService.update(product);
  }

  @Action
  public async get(id: string): Promise<IpdcProduct> {
    return this.ipdcProductService.get(id);
  }

  @Action
  public getThemeOptions(): void {
    this.context.commit('setThemeOptionsLoading', true);

    this._ipdcProductThemeService
      .search()
      .then(response => {
        this.context.commit('setThemeOptions', response?.items);
        this.context.commit('setThemeOptionsLoading', false);
      })
      .catch(() => this.context.commit('setThemeOptionsLoading', false));
  }

  @Mutation
  public setThemeOptions(options): void {
    this._themeOptions = options;
  }

  @Mutation
  public setThemeOptionsLoading(isLoading): void {
    this._themeOptionsLoading = isLoading;
  }
}
