import { Inject, injectable } from 'inversify-props';
import { SearchQueryData, SearchResponse } from '../../utility/definitions';
import { WidgetService } from '../../services';
import { HttpClientService, HTTP_CLIENT_SERVICE } from '../http-client';
import { DEFAULT_PAGING_SIZE } from '@/constants';
import { ImportedWidget, Widget } from '@govflanders/mbp-admin-panel-shared';

@injectable()
export class WidgetServiceImpl implements WidgetService {
  /**
   * Create a WidgetServiceImpl object.
   *
   * @param {HttpClientService} _httpClient
   *   An HTTP Client service.
   */
  public constructor(@Inject(HTTP_CLIENT_SERVICE) private _httpClient: HttpClientService) {}

  /**
   * Create an endpoint URL.
   *
   * @param {string} path
   *   Path which holds the API endpoint.
   * @param {object|null} [queryParams=null]
   *   Optional. An object which holds the query params.
   *
   * @return {URL}
   *   A URL object.
   */
  private createEndpointUrl(path: string, queryParams: object | null = null): URL {
    // Create URL for given path.
    const url = new URL(path, window.location.origin);
    // Check whether a query params object is present.
    if (queryParams !== null && typeof queryParams === 'object') {
      // Iterate through the query parameter property names.
      for (const [key, value] of Object.entries(queryParams)) {
        // Append the query parameter key/value pair.
        url.searchParams.append(key, value);
      }
    }

    return url;
  }

  /**
   * {@inheritdoc}
   */
  public async search(queryData: SearchQueryData): Promise<SearchResponse<Widget>> {
    // Create endpoint URL.
    const url = this.createEndpointUrl('/api/v1/widgets', {
      limit: DEFAULT_PAGING_SIZE,
      ...queryData,
    });
    // Resolve search response data.
    return this._httpClient
      .get(url.toString(), {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(response => response.json());
  }

  /**
   * {@inheritdoc}
   */
  public async create(widgetConfig: Widget): Promise<Widget> {
    // Create endpoint URL.
    const url = this.createEndpointUrl('/api/v1/widgets');
    // Resolve search response data.
    return this._httpClient
      .post(url.toString(), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(widgetConfig),
      })
      .then(response => response.json());
  }

  public async createImported(widgetConfig: ImportedWidget): Promise<Widget> {
    // Create endpoint URL.
    const url = this.createEndpointUrl('/api/v1/widgets/import');
    // Resolve search response data.
    return this._httpClient
      .post(url.toString(), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(widgetConfig),
      })
      .then(response => response.json());
  }

  /**
   * {@inheritdoc}
   */
  public async get(id: string): Promise<Widget> {
    // Create endpoint URL for given ID.
    const url = this.createEndpointUrl(`/api/v1/widgets/${id}`);
    // Resolve the widget config for given URL.
    return this._httpClient
      .get(url.toString(), {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(response => response.json());
  }

  /**
   * {@inheritdoc}
   */
  public async update(widgetConfig: Widget): Promise<Widget> {
    // Create endpoint URL.
    const url = this.createEndpointUrl(`/api/v1/widgets/${widgetConfig.id}`);
    // Resolve search response data.
    return this._httpClient
      .put(url.toString(), {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(widgetConfig),
      })
      .then(response => response.json());
  }

  /**
   * {@inheritdoc}
   */
  public async delete(id: string): Promise<void> {
    // Create endpoint URL for given ID.
    const url = this.createEndpointUrl(`/api/v1/widgets/${id}`);
    // Resolve the widget config for given URL.
    await this._httpClient.delete(url.toString());
  }
}
