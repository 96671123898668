export const mbpAppFeatures = [
  'appointment',
  'certificates',
  'contact',
  'contact-send',
  'covid-certificates',
  'covid-vaccination',
  'culture-connect',
  'culture-uitpas',
  'dossier',
  'dossier-detail',
  'ebox',
  'education-career',
  'education-learning-credit',
  'education-shaping',
  'education-grants',
  'family-composition',
  'family-disability',
  'family-growth-package',
  'family-identity',
  'family-lineage',
  'family-youth-care',
  'mobility-competence',
  'mobility-return',
  'news',
  'notifications',
  'onboarding-address',
  'onboarding-notifications',
  'onboarding-step-1',
  'onboarding-step-2',
  'onboarding-step-3',
  'parking',
  'real-estate-energy',
  'real-estate-property-pass',
  'recycle',
  'recycle-fostplus',
  'refresh',
  'report',
  'service-requests',
  'uitdatabank',
  'users',
  'work-career-break',
  'work-functions',
  'work-jobbonus',
  'work-political-mandates',
  'work-support-measures',
  'collect-user-data',
  'report-roads-traffic',
  'sports',
  'jobs',
];
