import { Role } from '@govflanders/mbp-admin-panel-shared';
import { RouteConfig } from 'vue-router';

/**
 *
 * @var {RouteConfig[]}
 */
export const CONTACT_PREFERENCE_ROUTES: RouteConfig[] = [
  {
    path: '/contact-preference',
    name: 'contact-preference',
    component: () =>
      import(
        /* webpackChunkName: "contact-preferences" */ '@/views/contact-preference/ContactPreferenceSearch.vue'
      ),
    meta: {
      requireRole: [Role.Read],
    },
  },
];
