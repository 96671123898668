import { Inject, injectable } from 'inversify-props';
import { HttpClientService, HTTP_CLIENT_SERVICE } from '../http-client';
import { DEFAULT_PAGING_SIZE } from '@/constants';
import { Certificate } from '@govflanders/mbp-admin-panel-shared';
import { SearchQueryData, SearchResponse } from '@/utility/definitions';

@injectable()
export class CertificatesService {
  private static readonly BASE_URL: string = '/api/v1/certificates';

  public constructor(@Inject(HTTP_CLIENT_SERVICE) private _httpClient: HttpClientService) {}

  public create(certificateConfig: Certificate): Promise<Certificate> {
    return this._httpClient
      .post(CertificatesService.BASE_URL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(certificateConfig),
      })
      .then(response => response.json());
  }

  public get(id: string): Promise<Certificate> {
    return this._httpClient
      .get(`${CertificatesService.BASE_URL}/${id}`, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(response => response.json());
  }

  public async find(queryData: SearchQueryData): Promise<SearchResponse<Certificate>> {
    const url = new URL(CertificatesService.BASE_URL, window.location.origin);
    url.searchParams.set('query', queryData.query);
    url.searchParams.set('offset', queryData.offset);
    url.searchParams.set('limit', queryData.limit || DEFAULT_PAGING_SIZE);

    // Resolve search response data.
    return this._httpClient
      .get(url.toString(), {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(response => response.json());
  }

  public update(certificate: Certificate): Promise<Certificate> {
    return this._httpClient
      .put(`${CertificatesService.BASE_URL}/${certificate.id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(certificate),
      })
      .then(response => response.json());
  }

  public delete(id: string): Promise<boolean> {
    return this._httpClient
      .delete(`${CertificatesService.BASE_URL}/${id}`)
      .then(response => response.json());
  }
}
