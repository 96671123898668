/**
 * Roles which can be assigned to a user.
 */
export const enum Role {
  /**
   * User can administer application.
   *
   * @var {string}
   */
  Admin = 'MBPAdminpaneelGebruiker-administrator',

  /**
   * User can only read and write configuration.
   *
   * @var {string}
   */
  Write = 'MBPAdminpaneelGebruiker-schrijver',

  /**
   * User can only read configurations.
   *
   * @var {string}
   */
  Read = 'MBPAdminpaneelGebruiker-raadpleger',
}
