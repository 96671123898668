import { Role } from '@govflanders/mbp-admin-panel-shared';
import { RouteConfig } from 'vue-router';

/**
 *
 * @var {RouteConfig[]}
 */
export const BRANDING_ROUTES: RouteConfig[] = [
  {
    path: '/branding',
    name: 'branding',
    component: () =>
      import(/* webpackChunkName: "branding" */ '@/views/branding/BrandingSearch.vue'),
    meta: {
      requireRole: [Role.Read],
    },
  },
  {
    path: '/branding/add',
    name: 'branding.add',
    component: () =>
      import(/* webpackChunkName: "branding-add" */ '@/views/branding/BrandingAdd.vue'),
    meta: {
      requireRole: [Role.Write],
    },
  },
  {
    path: '/branding/:id/edit',
    name: 'branding.edit',
    component: () =>
      import(/* webpackChunkName: "branding-edit" */ '@/views/branding/BrandingEdit.vue'),
    props: true,
    meta: {
      requireRole: [Role.Read],
    },
  },
  {
    path: '/branding/:id/delete',
    name: 'branding.delete',
    component: () =>
      import(/* webpackChunkName: "branding-delete" */ '@/views/branding/BrandingDelete.vue'),
    props: true,
    meta: {
      requireRole: [Role.Write],
    },
  },
];
