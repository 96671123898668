
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class App extends Vue {
  public created() {
    this.$store.dispatch('featureToggles/init');
    this.$store.dispatch('config/init');
  }
}
