/**
 * Enumaration which holds the available page header action appearences.
 */
export enum PageHeaderActionAppearences {
  /**
   * Action button should have default appearence.
   *
   * @var {string}
   */
  Default = 'default',

  /**
   * Action button should have an error-like appearence.
   *
   * @var {string}
   */
  Error = 'error',
}

/**
 * List of supported page header action appearences.
 *
 * @var {string[]}
 */
export const SUPPORT_PAGE_HEADER_ACTION_APPEARENCES = Object.values<string>(
  PageHeaderActionAppearences,
);

/**
 * Validate whether page header action appearence is valid.
 *
 * @param {any} value
 *   Value to validate.
 *
 * @return {boolean}
 *   True if valid, otherwise false.
 */
export function isValidPageHeaderActionAppearence(value: any): boolean {
  return SUPPORT_PAGE_HEADER_ACTION_APPEARENCES.indexOf(value) > -1;
}
