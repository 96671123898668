import { Role } from '@govflanders/mbp-admin-panel-shared';
import { RouteConfig } from 'vue-router';

/**
 * List of Widget related route configurations.
 *
 * @var {RouteConfig[]}
 */
export const WIDGET_ROUTES: RouteConfig[] = [
  {
    path: '/widgets',
    name: 'widgets',
    component: () => import(/* webpackChunkName: "widget" */ '@/views/widget/WidgetSearch.vue'),
    meta: {
      requireRole: [Role.Read],
    },
  },
  {
    path: '/widgets/import-overview',
    name: 'widgets-overview',
    component: () =>
      import(/* webpackChunkName: "widget" */ '@/views/widget/WidgetImportOverview.vue'),
    meta: {
      requireRole: [Role.Read],
    },
  },
  {
    path: '/widgets/add/:type',
    name: 'widgets.add',
    component: () => import(/* webpackChunkName: "widget" */ '@/views/widget/WidgetAdd.vue'),
    props: true,
    meta: {
      requireRole: [Role.Write],
    },
  },
  {
    path: '/widgets/:id/edit',
    name: 'widgets.edit',
    component: () => import(/* webpackChunkName: "widget" */ '@/views/widget/WidgetEdit.vue'),
    props: true,
    meta: {
      requireRole: [Role.Read],
    },
  },
  {
    path: '/widgets/:id/delete',
    name: 'widgets.delete',
    component: () => import(/* webpackChunkName: "widget" */ '@/views/widget/WidgetDelete.vue'),
    props: true,
    meta: {
      requireRole: [Role.Write],
    },
  },
];
