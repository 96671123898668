import { CertificateModule } from '@/store/modules/Certificate';
import { CertificateSearchModule } from '@/store/modules/CertificateSearch';
import { ContactModule } from '@/store/modules/Contact';
import { ContactSearchModule } from '@/store/modules/ContactSearch';
import Vue from 'vue';
import Vuex from 'vuex';
import {
  AppModule,
  BrandingModule,
  BrandingSearchModule,
  ConfigModule,
  ContactPreferenceModule,
  ContactPreferenceSearchModule,
  FeatureTogglesModule,
  IpdcProductModule,
  IpdcProductSearchModule,
  IpdcProductThemeModule,
  IpdcProductThemeSearchModule,
  MBPAppModule,
  MBPAppSearchModule,
  OrganisationSearchModule,
  WidgetImportModule,
  WidgetModule,
  WidgetSearchModule,
} from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app: AppModule,
    widget: WidgetModule,
    widgetSearch: WidgetSearchModule,
    widgetImport: WidgetImportModule,
    certificate: CertificateModule,
    certificateSearch: CertificateSearchModule,
    config: ConfigModule,
    contact: ContactModule,
    contactSearch: ContactSearchModule,
    featureToggles: FeatureTogglesModule,
    ipdcProduct: IpdcProductModule,
    ipdcProductSearch: IpdcProductSearchModule,
    ipdcProductThemeSearch: IpdcProductThemeSearchModule,
    ipdcProductTheme: IpdcProductThemeModule,
    organisationSearch: OrganisationSearchModule,
    MBPApp: MBPAppModule,
    MBPAppSearch: MBPAppSearchModule,
    branding: BrandingModule,
    brandingSearch: BrandingSearchModule,
    contactPreferenceSearch: ContactPreferenceSearchModule,
    contactPreference: ContactPreferenceModule,
  },
});
