import { authGuard, roleGuard } from '@/guards';
import { BRANDING_ROUTES } from '@/router/branding';
import { CERTIFICATE_ROUTES } from '@/router/certificates';
import { CONTACT_ROUTES } from '@/router/contacts';
import { IPDC_ROUTES } from '@/router/ipdc';
import { MBPAPP_ROUTES } from '@/router/mbp-app';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { CONTACT_PREFERENCE_ROUTES } from './contact-preferences';
import { DEFAULT_ROUTES } from './default';
import { WIDGET_ROUTES } from './widgets';

// Register the Vue Router plugin.
Vue.use(VueRouter);

/**
 * Default Vue Router instance.
 *
 * @var {VueRouter}
 */
const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/widgets/preview',
      component: () => import(/* webpackChunkName: "preview" */ '@/views/widget/WidgetPreview.vue'),
      meta: {
        isPublic: true,
      },
    },
    {
      path: '',
      component: () => import(/* webpackChunkName: "defaultPage" */ '@/layouts/DefaultPage.vue'),
      children: [
        ...WIDGET_ROUTES,
        ...CERTIFICATE_ROUTES,
        ...CONTACT_ROUTES,
        ...IPDC_ROUTES,
        ...MBPAPP_ROUTES,
        ...BRANDING_ROUTES,
        ...CONTACT_PREFERENCE_ROUTES,
        ...DEFAULT_ROUTES,
      ],
    },
  ],
});

// Global guards which provide access control.
router.beforeEach(authGuard);
router.beforeEach(roleGuard);

export default router;
