import {
  CONTACT_PREFERENCE_SERVICE,
  ContactPreferenceService,
} from '@/services/contact-preference';
import { Inject } from 'inversify-props';
import { Action, Module, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class ContactPreferenceModule extends VuexModule {
  @Inject(CONTACT_PREFERENCE_SERVICE)
  private contactPreferenceService!: ContactPreferenceService;

  @Action
  public async delete(email: string): Promise<boolean> {
    return this.contactPreferenceService.delete(email);
  }
}
