import 'reflect-metadata';
import { container } from 'inversify-props';
//import '@govflanders/vl-widget-polyfill';
import VlUiVueComponents, { VlModalToggle } from '@govflanders/vl-ui-vue-components';
import Vue from 'vue';
import Component from 'vue-class-component';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import UniqueIdPlugin from 'vue-unique-id';
import router from '@/router';
import registerServices from '@/services';
import store from '@/store';
import i18n from '@/i18n';
import App from '@/App.vue';
import { ValidationObserver } from 'vee-validate';

// Disable production tip being emitted in console.
Vue.config.productionTip = false;

// Provide component class based hook integration for Vue Router.
Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate']);

/**
 * Register Vue related plugins.
 */
Vue.use(Toast);
Vue.use(UniqueIdPlugin);
Vue.use(VlUiVueComponents);

/**
 * Register Vue related directives.
 */
Vue.directive('vl-modal-toggle', VlModalToggle);
Vue.component('ValidationObserver', ValidationObserver);

// Register our services.
registerServices(container);

// Create a Vue instance for our application.
const app = new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
});

// Mount the application to our DOM.
app.$mount('#app');
