import { PropOptions } from 'vue';

/**
 * Enumeration which holds the supported search filter types.
 */
export enum SearchFilterTypes {
  /**
   * Search filter should be represented as input text.
   */
  Input = 'input',

  /**
   * Search filter should be represented as radio-buttons.
   *
   * @var {string}
   */
  Radio = 'radio',
}

/**
 * List of supported search filter types.
 *
 * @var {string[]}
 */
export const SUPPORTED_SEARCH_FILTER_TYPES = Object.values<string>(SearchFilterTypes);

/**
 * Validate whether given search filter type is valid.
 *
 * @param {any} type
 *   Value which requires validation.
 *
 * @return {boolean}
 *   True if valid, otherwise false.
 */
export function isValidSearchFilterType(type: any): boolean {
  return SUPPORTED_SEARCH_FILTER_TYPES.indexOf(type) > -1;
}

/**
 * Component property options for SearchQueryData.
 *
 * @var {PropOptions<SearchQueryData>}
 */
export const SEARCH_FILTER_TYPE_PROP_OPTIONS = {
  type: Object,
  validator: isValidSearchFilterType,
} as PropOptions<SearchFilterTypes>;
