import { Inject, injectable } from 'inversify-props';
import { HttpClientService, HTTP_CLIENT_SERVICE } from '../http-client';
import { DEFAULT_PAGING_SIZE } from '@/constants';
import { Branding } from '@govflanders/mbp-admin-panel-shared';
import { SearchQueryData, SearchResponse } from '@/utility/definitions';

@injectable()
export class BrandingService {
  private static readonly BASE_URL: string = '/api/v1/config/branding';

  public constructor(@Inject(HTTP_CLIENT_SERVICE) private _httpClient: HttpClientService) {}

  public create(brandingConfig: Branding): Promise<Branding> {
    return this._httpClient
      .post(BrandingService.BASE_URL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(brandingConfig),
      })
      .then(response => response.json());
  }

  public get(id: string): Promise<Branding> {
    return this._httpClient
      .get(`${BrandingService.BASE_URL}/${id}`, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(response => response.json());
  }

  public async find(queryData: SearchQueryData): Promise<SearchResponse<Branding>> {
    const url = new URL(BrandingService.BASE_URL, window.location.origin);
    url.searchParams.set('query', queryData.query);
    url.searchParams.set('offset', queryData.offset);
    url.searchParams.set('limit', queryData.limit || DEFAULT_PAGING_SIZE);

    // Resolve search response data.
    return this._httpClient
      .get(url.toString(), {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(response => response.json())
      .catch(error => console.log('😔 HOHOHO', url.toString(), error));
  }

  public update(branding: Branding): Promise<Branding> {
    return this._httpClient
      .put(`${BrandingService.BASE_URL}/${branding.id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(branding),
      })
      .then(response => response.json());
  }

  public delete(id: string): Promise<boolean> {
    return this._httpClient
      .delete(`${BrandingService.BASE_URL}/${id}`)
      .then(response => response.json());
  }
}
