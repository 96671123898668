import AsyncLock from 'async-lock';
import { Inject } from 'inversify-props';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { SearchResponse, SearchPageMetaData, SearchQueryData } from '@/utility/definitions';
import { Certificate } from '@govflanders/mbp-admin-panel-shared';
import { CERTIFICATES_SERVICE, CertificatesService } from '@/services/certificates';

@Module({ namespaced: true })
export class CertificateSearchModule extends VuexModule {
  public response: SearchResponse<Certificate> | null = null;
  public error: Error | null = null;

  /**
   * Internal async lock used to prevent multiple search operations from
   * conflicting.
   *
   * @var {AsyncLock}
   */
  private _lock = new AsyncLock();

  @Inject(CERTIFICATES_SERVICE)
  private certificatesService!: CertificatesService;

  public get isFailed(): boolean {
    return this.error !== null;
  }

  public get isSuccess(): boolean {
    return !this.isFailed && !this.isLoading;
  }

  public get isLoading(): boolean {
    return !this.isFailed && this.response === null;
  }

  public get results(): Certificate[] | null {
    if (this.response && this.isSuccess) {
      return this.response.content;
    }

    return null;
  }

  public get pageMetaData(): SearchPageMetaData | null {
    if (this.response && this.isSuccess) {
      return this.response.pageMetadata;
    }

    return null;
  }

  @Mutation
  public setLoading(): void {
    this.response = null;
    this.error = null;
  }

  @Mutation
  public setSuccess(searchResponse: SearchResponse<Certificate>): void {
    this.response = searchResponse;
    this.error = null;
  }

  @Mutation
  public setFailed(error: Error): void {
    this.error = error;
    this.response = null;
  }

  @Action
  public async search(queryData: SearchQueryData): Promise<void> {
    // Do not allow parallel execution to prevent conflicting states to be resolved or
    // the results to get out of sync with the query.
    return this._lock.acquire('execute', async () => {
      this.context.commit('setLoading');
      await this.certificatesService
        .find(queryData)
        .then(response => {
          this.context.commit('setSuccess', response);
        })
        .catch(error => {
          this.context.commit('setFailed', error);
        });
    });
  }
}
