import { Inject, injectable } from 'inversify-props';
import { HttpClientService, HTTP_CLIENT_SERVICE } from '../http-client';
import { Contact } from '@govflanders/mbp-admin-panel-shared';
import { SearchQueryData, SearchResponse } from '@/utility/definitions';

@injectable()
export class OrganisationsService {
  private static readonly BASE_URL: string = '/api/v1/organisations';

  public constructor(@Inject(HTTP_CLIENT_SERVICE) private _httpClient: HttpClientService) {}

  public async find(queryData: SearchQueryData): Promise<SearchResponse<Contact>> {
    if (queryData.query) {
      const url = new URL(OrganisationsService.BASE_URL, window.location.origin);
      url.searchParams.set('query', queryData.query);

      return this._httpClient
        .get(url.toString(), {
          headers: {
            Accept: 'application/json',
          },
        })
        .then(response => response.json());
    }

    return null;
  }
}
