import { Inject, injectable } from 'inversify-props';
import { WidgetImportService } from './definition';
import { ImportedWidget } from '@govflanders/mbp-admin-panel-shared';
import { HttpClientService, HTTP_CLIENT_SERVICE } from '../http-client';

@injectable()
export class WidgetImportServiceImpl implements WidgetImportService {
  /**
   * Create a WidgetServiceImpl object.
   *
   * @param {HttpClientService} _httpClient
   *   An HTTP Client service.
   */
  public constructor(@Inject(HTTP_CLIENT_SERVICE) private _httpClient: HttpClientService) {}

  /**
   * Create an endpoint URL.
   *
   * @param {string} path
   *   Path which holds the API endpoint.
   * @param {object|null} [queryParams=null]
   *   Optional. An object which holds the query params.
   *
   * @return {URL}
   *   A URL object.
   */
  private static createEndpointUrl(path: string, queryParams: object | null = null): URL {
    // Create URL for given path.
    const url = new URL(path, window.location.origin);
    // Check whether a query params object is present.
    if (queryParams !== null && typeof queryParams === 'object') {
      // Iterate through the query parameter property names.
      for (const [key, value] of Object.entries(queryParams)) {
        // Append the query parameter key/value pair.
        url.searchParams.append(key, value);
      }
    }

    return url;
  }

  /**
   * {@inheritdoc}
   */
  getWidgetsFromRequest(requestId: string): Promise<ImportedWidget[]> {
    const url = WidgetImportServiceImpl.createEndpointUrl('/api/v1/widget-requests', { requestId });

    return this._httpClient
      .get(url.toString(), {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(response => response.json());
  }
}
