import AsyncLock from 'async-lock';
import { Inject } from 'inversify-props';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { SearchQueryData } from '@/utility/definitions';
import { ORGANISATIONS_SERVICE, OrganisationsService } from '@/services/organisations';

@Module({ namespaced: true })
export class OrganisationSearchModule extends VuexModule {
  public response: string[] | null = null;
  public callIsLoading = false;
  public error: Error | null = null;

  private _lock = new AsyncLock();

  @Inject(ORGANISATIONS_SERVICE)
  private organisationsService!: OrganisationsService;

  public get isFailed(): boolean {
    return this.error !== null;
  }

  public get isSuccess(): boolean {
    return !this.isFailed && !this.isLoading;
  }

  public get isLoading(): boolean {
    return this.callIsLoading;
  }

  public get results(): string[] | null {
    if (this.response && this.isSuccess) {
      return this.response;
    }

    return [];
  }

  @Mutation
  public setLoading(): void {
    this.response = null;
    this.error = null;
  }

  @Mutation
  public setSuccess(response: string[]): void {
    this.response = response;
    this.error = null;
  }

  @Mutation
  public setFailed(error: Error): void {
    this.error = error;
    this.response = null;
  }

  @Mutation
  public setCallIsLoading(value: boolean): void {
    this.callIsLoading = value;
  }

  @Action
  public async search(queryData: SearchQueryData): Promise<void> {
    return this._lock.acquire('execute', async () => {
      this.context.commit('setLoading');
      this.context.commit('setCallIsLoading', true);
      await this.organisationsService
        .find(queryData)
        .then(response => {
          this.context.commit('setSuccess', response);
        })
        .finally(() => {
          this.context.commit('setCallIsLoading', false);
        })
        .catch(error => {
          this.context.commit('setFailed', error);
        });
    });
  }
}
