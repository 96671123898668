import { Role } from '@govflanders/mbp-admin-panel-shared';
import { RouteConfig } from 'vue-router';

/**
 *
 * @var {RouteConfig[]}
 */
export const MBPAPP_ROUTES: RouteConfig[] = [
  {
    path: '/mbp-app',
    name: 'mbp-app',
    component: () => import(/* webpackChunkName: "mbp-app" */ '@/views/mbp-app/MBPAppSearch.vue'),
    meta: {
      requireRole: [Role.Read],
    },
  },
  {
    path: '/mbp-app/add',
    name: 'mbp-app.add',
    component: () => import(/* webpackChunkName: "mbp-app-add" */ '@/views/mbp-app/MBPAppAdd.vue'),
    meta: {
      requireRole: [Role.Write],
    },
  },
  {
    path: '/mbp-app/:id/edit',
    name: 'mbp-app.edit',
    component: () =>
      import(/* webpackChunkName: "mbp-app-edit" */ '@/views/mbp-app/MBPAppEdit.vue'),
    props: true,
    meta: {
      requireRole: [Role.Read],
    },
  },
  {
    path: '/mbp-app/:id/delete',
    name: 'mbp-app.delete',
    component: () =>
      import(/* webpackChunkName: "mbp-app-delete" */ '@/views/mbp-app/MBPAppDelete.vue'),
    props: true,
    meta: {
      requireRole: [Role.Write],
    },
  },
];
