import { SearchQueryData, SearchResponse } from '../../utility/definitions';
import { ImportedWidget, Widget } from '@govflanders/mbp-admin-panel-shared';

/**
 * Holds the Widget Config Service name.
 *
 * @var {Symbol}
 */
export const WIDGET_SERVICE = Symbol.for('WIDGET_SERVICE');

/**
 * Interface which describes a Widget Config service.
 */
export interface WidgetService {
  /**
   * Search widget config matching given query.
   *
   * @param {SearchQueryData} queryData
   *   An object which represents the search query data.
   *
   * @return {Promise<SearchResponse<Widget>>}
   *   A promise to find widget config objects matching query data.
   */
  search(queryData: SearchQueryData): Promise<SearchResponse<Widget>>;

  /**
   * Create given widget config.
   *
   * @param {Widget} widgetConfig
   *   An object which holds the widget config.
   *
   * @return {Promise<Widget>}
   *   A promise to create the widget config.
   */
  create(widgetConfig: Widget): Promise<Widget>;

  /**
   * Create given imported widget config.
   *
   * @param {ImportedWidget} widgetConfig
   *   An object which holds the widget config.
   *
   * @return {Promise<Widget>}
   *   A promise to create the widget config.
   */
  createImported(widgetConfig: ImportedWidget): Promise<Widget>;

  /**
   * Get a widget configuration for given identifier.
   *
   * @param {string} id
   *   A widget config ID.
   *
   * @return {Promise<Widget>}
   *   A promise to retrieve the widget config.
   */
  get(id: string): Promise<Widget>;

  /**
   * Update given widget config.
   *
   * @param {Widget} widgetConfig
   *   An object which holds the widget config.
   *
   * @return {Promise<Widget>}
   *   A promise to update the widget config.
   */
  update(widgetConfig: Widget): Promise<Widget>;

  /**
   * Delete widget config for given identifier.
   *
   * @param {string} id
   *   A widget config ID.
   *
   * @return {Promise<Widget>}
   *   A promise to delete the widget config.
   */
  delete(id: string): Promise<void>;
}
