import { ConfigValueName } from '@govflanders/mbp-admin-panel-shared';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class ConfigModule extends VuexModule {
  private config: Record<ConfigValueName, string> | undefined;

  public get get(): (ConfigValueName) => string {
    return (name: ConfigValueName) => {
      return this.config && this.config[name];
    };
  }

  @Mutation
  public setConfigValues(config: Record<ConfigValueName, string> | undefined): void {
    this.config = config;
  }

  @Action
  public init(): void {
    try {
      const config: { config: Record<ConfigValueName, string> } = (window as any).__CONFIG__;

      if (config) {
        this.context.commit('setConfigValues', config);
      } else {
        console.error('No config found, aborting init');
      }
    } catch (e) {
      console.error(e);
    }
  }
}
