import {
  CONTACT_PREFERENCE_SERVICE,
  ContactPreferenceService,
} from '@/services/contact-preference';
import { SearchPageMetaData, SearchResponse } from '@/utility/definitions';
import { ContactPreference } from '@govflanders/mbp-admin-panel-shared';
import AsyncLock from 'async-lock';
import { Inject } from 'inversify-props';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class ContactPreferenceSearchModule extends VuexModule {
  public response: SearchResponse<ContactPreference> | null = null;
  public error: Error | null = null;
  public loading = false;

  /**
   * Internal async lock used to prevent multiple search operations from
   * conflicting.
   *
   * @var {AsyncLock}
   */
  private _lock = new AsyncLock();

  @Inject(CONTACT_PREFERENCE_SERVICE)
  private contactPreferenceService!: ContactPreferenceService;

  public get isFailed(): boolean {
    return this.error !== null;
  }

  public get isSuccess(): boolean {
    return !this.isFailed && !this.isLoading;
  }

  public get isLoading(): boolean {
    return this.loading;
  }

  public get results(): ContactPreference[] {
    if (this.response && this.isSuccess) {
      return this.response.content;
    }

    return [];
  }

  public get pageMetaData(): SearchPageMetaData | null {
    if (this.response && this.isSuccess) {
      return this.response.pageMetadata;
    }

    return null;
  }

  @Mutation
  public setLoading(loading): void {
    this.loading = loading;
  }
  @Mutation
  public reset(): void {
    this.response = null;
    this.error = null;
  }

  @Mutation
  public setSuccess(searchResponse: SearchResponse<ContactPreference>): void {
    this.response = searchResponse;
    this.error = null;
  }

  @Mutation
  public setFailed(error: Error): void {
    this.error = error;
    this.response = null;
  }

  @Action
  public async search(email: string): Promise<ContactPreference[]> {
    // Do not allow parallel execution to prevent conflicting states to be resolved or
    // the results to get out of sync with the query.
    return new Promise((resolve, reject) =>
      this._lock.acquire('execute', async () => {
        this.context.commit('reset');
        this.context.commit('setLoading', true);
        await this.contactPreferenceService
          .search(email)
          .then(response => {
            this.context.commit('setSuccess', response);
            resolve(response);
          })
          .catch(error => {
            this.context.commit('setFailed', error);
            reject();
          })
          .finally(() => this.context.commit('setLoading', false));
      }),
    );
  }
}
