import { Inject } from 'inversify-props';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { IpdcProductTheme } from '@govflanders/mbp-admin-panel-shared';
import { IPDC_PRODUCT_THEME_SERVICE, IpdcProductThemeService } from '@/services/ipdc';

@Module({ namespaced: true })
export class IpdcProductThemeModule extends VuexModule {
  @Inject(IPDC_PRODUCT_THEME_SERVICE)
  private _ipdcProductThemeService!: IpdcProductThemeService;

  @Action
  public async create(ipdcProductThemeConfig: IpdcProductTheme): Promise<IpdcProductTheme> {
    return this._ipdcProductThemeService.create(ipdcProductThemeConfig);
  }

  @Action
  public async get(id: string): Promise<IpdcProductTheme> {
    return this._ipdcProductThemeService.get(id);
  }

  @Action
  public async update(ipdcProductThemeConfig: IpdcProductTheme): Promise<IpdcProductTheme> {
    return this._ipdcProductThemeService.update(ipdcProductThemeConfig);
  }

  @Action
  public async bulkUpdate(ipdcProductThemes: IpdcProductTheme[]): Promise<IpdcProductTheme[]> {
    return this._ipdcProductThemeService.bulkUpdate(ipdcProductThemes);
  }

  @Action
  public async delete(id: string): Promise<boolean> {
    return this._ipdcProductThemeService.delete(id);
  }
}
