import { Inject } from 'inversify-props';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { WidgetService, WIDGET_SERVICE } from '@/services';
import { ImportedWidget, Widget } from '@govflanders/mbp-admin-panel-shared';
import { SearchFilterDefinitions, SearchFilterTypes } from '@/utility/definitions';

@Module({ namespaced: true })
export class WidgetModule extends VuexModule {
  /**
   * Holds the widget config service.
   *
   * @var {WidgetService}
   */
  @Inject(WIDGET_SERVICE)
  private _widgetService!: WidgetService;

  /**
   * Create given widget config.
   *
   * @param {Widget} widgetConfig
   *   An object which holds the widget config.
   *
   * @return {Promise<Widget>}
   *   A promise to create the widget config.
   */
  @Action
  public async create(widgetConfig: Widget): Promise<Widget> {
    return this._widgetService.create(widgetConfig);
  }

  /**
   * Create given imported widget config.
   *
   * @param {ImportedWidget} widgetConfig
   *   An object which holds the widget config.
   *
   * @return {Promise<Widget>}
   *   A promise to create the widget config.
   */
  @Action
  public async createImported(widgetConfig: ImportedWidget): Promise<Widget> {
    return this._widgetService.createImported(widgetConfig);
  }

  /**
   * Get a widget configuration for given identifier.
   *
   * @param {string} id
   *   A widget config ID.
   *
   * @return {Promise<Widget>}
   *   A promise to retrieve the widget config.
   */
  @Action
  public async get(id: string): Promise<Widget> {
    return this._widgetService.get(id);
  }

  /**
   * Update given widget config.
   *
   * @param {Widget} widgetConfig
   *   An object which holds the widget config.
   *
   * @return {Promise<Widget>}
   *   A promise to update the widget config.
   */
  @Action
  public async update(widgetConfig: Widget): Promise<Widget> {
    return this._widgetService.update(widgetConfig);
  }

  /**
   * Delete widget config for given identifier.
   *
   * @param {string} id
   *   A widget config ID.
   *
   * @return {Promise<Widget>}
   *   A promise to delete the widget config.
   */
  @Action
  public async delete(id: string): Promise<void> {
    return this._widgetService.delete(id);
  }
}
