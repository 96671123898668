import AsyncLock from 'async-lock';
import { Inject } from 'inversify-props';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import {
  SearchFilterDefinitions,
  SearchFilterTypes,
  SearchResponse,
  SearchPageMetaData,
  SearchQueryData,
} from '@/utility/definitions';
import { WidgetService, WIDGET_SERVICE } from '@/services';
import { Widget } from '@govflanders/mbp-admin-panel-shared';

/**
 * Interface which describes the Widget Search Module state.
 */
export interface WidgetSearchModuleState {
  /**
   * Holds the actual search response.
   *
   * @var {SearchResponse<Widget>|null}
   */
  _searchResponse: SearchResponse<Widget> | null;

  /**
   * Holds the error which caused a failed state.
   *
   * @var {Error|null}
   */
  _searchResponseError: Error | null;
}

@Module({ namespaced: true })
export class WidgetSearchModule extends VuexModule implements WidgetSearchModuleState {
  /**
   * Holds the actual search response.
   *
   * @var {SearchResponse<Widget>|null}
   */
  public _searchResponse: SearchResponse<Widget> | null = null;

  /**
   * Holds the error which caused a failed state.
   *
   * @var {Error|null}
   */
  public _searchResponseError: Error | null = null;

  /**
   * Internal async lock used to prevent multiple search operations from
   * conflicting.
   *
   * @var {AsyncLock}
   */
  private _lock = new AsyncLock();

  /**
   * Holds the widget config service.
   *
   * @var {WidgetService}
   */
  @Inject(WIDGET_SERVICE)
  private _widgetService!: WidgetService;

  /**
   * Get a value indicating whether search operation has failed.
   *
   * @return {boolean}
   *   True if search operation failed, otherwise false.
   */
  public get isFailed(): boolean {
    return this._searchResponseError !== null;
  }

  /**
   * Get a value indicating whether search operation is successful.
   *
   * @return {boolean}
   *   True if search operation is successful, otherwise false.
   */
  public get isSuccess(): boolean {
    return !this.isFailed && !this.isLoading;
  }

  /**
   * Get a value indicating whether search operation is loading.
   *
   * @return {boolean}
   *   True if search operation is loading, otherwise false.
   */
  public get isLoading(): boolean {
    return !this.isFailed && this._searchResponse === null;
  }

  /**
   * Get the search response results.
   *
   * @return {Widget[]|null}
   *   An array of Widget objects if search operation was successful, otherwise null.
   */
  public get results(): Widget[] | null {
    // Check whether search operation was successful.
    if (this._searchResponse && this.isSuccess) {
      // Use the results from the search response.
      return this._searchResponse.content;
    }

    return null;
  }

  /**
   * Get the search response page meta data.
   *
   * @return {SearchPageMetaData|null}
   *   An object which holds the search page meta data if search operation was successful, otherwise null.
   */
  public get pageMetaData(): SearchPageMetaData | null {
    // Check whether search operation was successful.
    if (this._searchResponse && this.isSuccess) {
      // Use the page meta data from the search response.
      return this._searchResponse.pageMetadata;
    }

    return null;
  }

  /**
   * Get the search filter definitions.
   *
   * @return {SearchFilterDefinitions}
   *   An array of search filter definitions.
   */
  public get filters(): SearchFilterDefinitions {
    return [
      {
        type: SearchFilterTypes.Input,
        name: 'query',
        label: 'widgets.search.filters.query.label',
      },
      {
        type: SearchFilterTypes.Radio,
        name: 'type',
        label: 'widgets.search.filters.type.label',
        options: [
          {
            label: 'widgets.search.filters.type.allTypes',
            value: null,
          },
          {
            label: 'widgets.types.@govflanders/vl-widget-plugin-global-header:global_header',
            value: '@govflanders/vl-widget-plugin-global-header:global_header',
          },
          {
            label: 'widgets.types.@govflanders/vl-widget-plugin-global-footer:global_footer',
            value: '@govflanders/vl-widget-plugin-global-footer:global_footer',
          },
        ],
      },
    ];
  }

  /**
   * Transition to a loading state.
   */
  @Mutation
  public loading(): void {
    // Clear error first to prevent UI from showing error before
    // transition to loading state.
    this._searchResponse = null;
    this._searchResponseError = null;
  }

  /**
   * Transition to a success state.
   *
   * @param {SearchResponse<Widget>} searchResponse
   *   Resolved search response.
   */
  @Mutation
  public success(searchResponse: SearchResponse<Widget>): void {
    this._searchResponse = searchResponse;
    this._searchResponseError = null;
  }

  /**
   * Transition to a failed state.
   *
   * @param {Error} searchResponseError
   *   Error which occurred during search operation.
   */
  @Mutation
  public failed(searchResponseError: Error): void {
    this._searchResponseError = searchResponseError;
    this._searchResponse = null;
  }

  /**
   * Perform a widget search operation for given query data.
   *
   * @param {SearchQueryData} queryData
   *   An object which represents the search query data.
   *
   * @return {Promise<void>}
   *   A promise to perform the search query.
   */
  @Action
  public async execute(queryData: SearchQueryData): Promise<void> {
    // Do not allow parallel execution to prevent conflicting states to be resolved or
    // the results to get out of sync with the query.
    return this._lock.acquire('execute', async () => {
      // Transition to a loading state.
      this.context.commit('loading');
      // Request widget search results for given query.
      await this._widgetService
        .search(queryData)
        .then(response => {
          // Transition to a success state for given response.
          this.context.commit('success', response);
        })
        .catch(error => {
          // Transition to a failed state for given error.
          this.context.commit('failed', error);
        });
    });
  }
}
