import { RouteConfig } from 'vue-router';

/**
 * Build a list of available routes.
 */
export const DEFAULT_ROUTES: RouteConfig[] = [
  {
    path: '/',
    name: 'homepage',
    redirect: '/widgets',
  },
  {
    path: '/access-denied',
    name: 'accessDenied',
    component: () => import(/* webpackChunkName: "common" */ '@/views/common/AccessDenied.vue'),
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "common" */ '@/views/common/NotFound.vue'),
  },
];
