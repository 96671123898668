import { Inject, injectable } from 'inversify-props';
import { HttpClientService, HTTP_CLIENT_SERVICE } from '../http-client';
import { DEFAULT_PAGING_SIZE } from '@/constants';
import { MBPApp } from '@govflanders/mbp-admin-panel-shared';
import { SearchQueryData, SearchResponse } from '@/utility/definitions';

@injectable()
export class MBPAppService {
  private static readonly BASE_URL: string = '/api/v1/mbp-app';

  public constructor(@Inject(HTTP_CLIENT_SERVICE) private _httpClient: HttpClientService) {}

  public create(mbpAppConfig: MBPApp): Promise<MBPApp> {
    return this._httpClient
      .post(MBPAppService.BASE_URL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mbpAppConfig),
      })
      .then(response => response.json());
  }

  public get(id: string): Promise<MBPApp> {
    return this._httpClient
      .get(`${MBPAppService.BASE_URL}/${id}`, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(response => response.json());
  }

  public async find(queryData: SearchQueryData): Promise<SearchResponse<MBPApp>> {
    const url = new URL(MBPAppService.BASE_URL, window.location.origin);
    url.searchParams.set('query', queryData.query);
    url.searchParams.set('offset', queryData.offset);
    url.searchParams.set('limit', queryData.limit || DEFAULT_PAGING_SIZE);

    // Resolve search response data.
    return this._httpClient
      .get(url.toString(), {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(response => response.json());
  }

  public update(mbpApp: MBPApp): Promise<MBPApp> {
    return this._httpClient
      .put(`${MBPAppService.BASE_URL}/${mbpApp.name}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mbpApp),
      })
      .then(response => response.json());
  }

  public delete(id: string): Promise<boolean> {
    return this._httpClient
      .delete(`${MBPAppService.BASE_URL}/${id}`)
      .then(response => response.json());
  }
}
