import { BRANDING_SERVICE, BrandingService } from '@/services/branding';
import { CERTIFICATES_SERVICE, CertificatesService } from '@/services/certificates';
import {
  CONTACT_PREFERENCE_SERVICE,
  ContactPreferenceService,
} from '@/services/contact-preference';
import { CONTACTS_SERVICE, ContactsService } from '@/services/contacts';
import {
  IPDC_PRODUCT_SERVICE,
  IPDC_PRODUCT_THEME_SERVICE,
  IpdcProductService,
  IpdcProductThemeService,
} from '@/services/ipdc';
import { MBPAPP_SERVICE, MBPAppService } from '@/services/mbp-app';
import { ORGANISATIONS_SERVICE, OrganisationsService } from '@/services/organisations';
import { Container } from 'inversify-props';
import { FetchHttpClientService, HTTP_CLIENT_SERVICE, HttpClientService } from './http-client';
import { LogoService } from './shared';
import { LOGO_SERVICE, WIDGET_SERVICE, WidgetService, WidgetServiceImpl } from './widget';
import {
  WIDGET_IMPORT_SERVICE,
  WidgetImportService,
  WidgetImportServiceImpl,
} from './widgetImport';

export * from './certificates';
export * from './http-client';
export * from './shared';
export * from './widget';
export * from './widgetImport';

/**
 * Register services which our dependency injection container.
 *
 * @param {Container} container
 *   An object which represents the dependency injection container.
 */
export default function registerServices(container: Container) {
  container.addTransient<HttpClientService>(FetchHttpClientService, HTTP_CLIENT_SERVICE);
  container.addTransient<WidgetService>(WidgetServiceImpl, WIDGET_SERVICE);
  container.addTransient<WidgetImportService>(WidgetImportServiceImpl, WIDGET_IMPORT_SERVICE);
  container.addTransient<CertificatesService>(CertificatesService, CERTIFICATES_SERVICE);
  container.addTransient<ContactsService>(ContactsService, CONTACTS_SERVICE);
  container.addTransient<OrganisationsService>(OrganisationsService, ORGANISATIONS_SERVICE);
  container.addTransient<IpdcProductService>(IpdcProductService, IPDC_PRODUCT_SERVICE);
  container.addTransient<LogoService>(LogoService, LOGO_SERVICE);
  container.addTransient<IpdcProductThemeService>(
    IpdcProductThemeService,
    IPDC_PRODUCT_THEME_SERVICE,
  );
  container.addTransient<MBPAppService>(MBPAppService, MBPAPP_SERVICE);
  container.addTransient<BrandingService>(BrandingService, BRANDING_SERVICE);
  container.addTransient<ContactPreferenceService>(
    ContactPreferenceService,
    CONTACT_PREFERENCE_SERVICE,
  );
}
