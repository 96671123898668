enum CertificateType {
  DIGITAAL = 0,
  DOORVERWIJZING = 1,
}

enum CertificateAbout {
  MEZELF = 1, // Enkel over mezelf
  ANDERE = 2, // Enkel over iemand anders
  MEZELF_EN_ANDERE = 3, // Over mezelf en voor iemand anders
}

enum AuthenticationMethod {
  TOKEN_EXCHANGE = 'TOKEN_EXCHANGE',
  ID_TOKEN = 'ID_TOKEN',
  GEOSECURE = 'GEOSECURE',
}

enum SourceType {
  RIJKSREGISTER = 'RR',
  AGENTSCHAP_NATUUR_EN_BOS = 'ANB',
  EXTERN = 'EXT',
  EXTERN_OAUTH = 'EXT_OAUTH',
  EXTERN_HAL = 'EXTHAL',
}

enum CityCertificateType {
  ONBEKEND = 0, // "Geef de stad of gemeente in waar de akte werd opgemaakt
  GEBOORTE = 1, // "Geef de stad of gemeente van geboorte in
  HUWELIJK = 2, // "Geef de stad of gemeente in waar het huwelijk werd voltrokken
  BEHEERDER = 3, // "Geef de stad of gemeente van woonst in
  OVERLIJDEN = 4, // "Geef de stad of gemeente van overlijden, of de laatste gemeente van woonst in
  ERKENNING = 5, // "Geef de stad of gemeente in waar de erkenningsakte is opgemaakt
  ADOPTIE = 6, // "Geef de stad of gemeente in waar het adoptievonnis is overgeschreven
}

interface Certificate {
  id?: string;
  createdAt?: string;
  lastModifiedAt?: string;
  parentName: string;
  active: boolean;
  name: string;
  description: string;
  displayOrder: number;
  productPageUrl: string;
  category: {
    key: string;
    value: string;
  };
  alternativeCategories: {
    key: string;
    value: string;
  }[];
  popularity: number;
  keywords: string[];
  deliveringService: {
    key: string;
    value: string;
  };
  type: CertificateType;
  aboutWhom: CertificateAbout;
  cityCertificateType: CityCertificateType;
  languages: string[];
  validityPeriods?: number[];
  sourceConnector: {
    type: SourceType;
    url?: string;
    certificateName: string;
    authentication: {
      method?: AuthenticationMethod;
      audience?: string;
    };
  };
  slowSource: boolean;
}

export {
  Certificate,
  CertificateType,
  CertificateAbout,
  AuthenticationMethod,
  SourceType,
  CityCertificateType,
};
