/**
 * Enumaration which holds the available page header action appearences.
 */
export enum PageHeaderActionTypes {
  /**
   * Show an action as primary importance.
   *
   * @var {string}
   */
  Primary = 'primary',

  /**
   * Show an action as secondary importance.
   *
   * @var {string}
   */
  Secondary = 'secondary',

  /**
   * Show an action as tertiary importance.
   *
   * @var {string}
   */
  Tertiary = 'tertiary',
}

/**
 * List of supported page header action types.
 *
 * @var {string[]}
 */
export const SUPPORT_PAGE_HEADER_ACTION_TYPES = Object.values<string>(PageHeaderActionTypes);

/**
 * Validate whether page header action type is valid.
 *
 * @param {any} value
 *   Value to validate.
 *
 * @return {boolean}
 *   True if valid, otherwise false.
 */
export function isValidPageHeaderActionType(value: any): boolean {
  return SUPPORT_PAGE_HEADER_ACTION_TYPES.indexOf(value) > -1;
}
