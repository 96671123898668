import { Inject, injectable } from 'inversify-props';
import { HTTP_CLIENT_SERVICE, HttpClientService } from '../http-client';

export const LOGO_SERVICE = Symbol.for('LOGO_SERVICE');

@injectable()
export class LogoService {
  public constructor(@Inject(HTTP_CLIENT_SERVICE) private _httpClient: HttpClientService) {}

  private readonly baseUrl = '/api/v1/logos';

  public upload(formData, directory: string): Promise<{ response: string }> {
    return this._httpClient
      .post(`${this.baseUrl}/${directory}`, {
        body: formData,
      })
      .then(response => response.json());
  }

  public fetchFilenames(directory: string): Promise<string[]> {
    return this._httpClient.get(`${this.baseUrl}/${directory}`).then(response => response.json());
  }
}
