import { NavigationGuardNext, Route } from 'vue-router';
import { get } from 'lodash';
import store from '@/store';

/**
 * Navigation guard which requires user have a specific role if a route
 * annotated with role meta-data.
 *
 * @param {Route} to
 *   Route to which navigation will be performed.
 * @param {Route} from
 *   Previously active route.
 * @param {NavigationGuardNext} next
 *   Function used resolve guard result.
 */
export function roleGuard(to: Route, from: Route, next: NavigationGuardNext): void {
  try {
    if (get(to.meta, 'isPublic')) {
      next();
    } else {
      const requiredRoles = get(to.meta, 'requireRole') || null;

      if (Array.isArray(requiredRoles)) {
        const user = store.getters['app/user'];
        if (!user || !requiredRoles.some(role => user.roles?.includes(role))) {
          next({ name: 'accessDenied' });
        }
      }
    }
  } catch (error) {
    console.error('RoleGuard failed', error);
    next({ name: 'accessDenied' });
  }

  next();
}
