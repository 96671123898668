import { Role } from '@govflanders/mbp-admin-panel-shared';
import { RouteConfig } from 'vue-router';

/**
 * List of Widget related route configurations.
 *
 * @var {RouteConfig[]}
 */
export const CERTIFICATE_ROUTES: RouteConfig[] = [
  {
    path: '/certificates',
    name: 'certificates',
    component: () =>
      import(/* webpackChunkName: "certificates" */ '@/views/certificate/CertificateSearch.vue'),
    meta: {
      requireRole: [Role.Read],
    },
  },
  {
    path: '/certificates/add',
    name: 'certificates.add',
    component: () =>
      import(/* webpackChunkName: "widget" */ '@/views/certificate/CertificateAdd.vue'),
    meta: {
      requireRole: [Role.Write],
    },
  },
  {
    path: '/certificates/:id/edit',
    name: 'certificates.edit',
    component: () =>
      import(/* webpackChunkName: "certificates" */ '@/views/certificate/CertificateEdit.vue'),
    props: true,
    meta: {
      requireRole: [Role.Read],
    },
  },
  {
    path: '/certificates/:id/delete',
    name: 'certificates.delete',
    component: () =>
      import(/* webpackChunkName: "widget" */ '@/views/certificate/CertificateDelete.vue'),
    props: true,
    meta: {
      requireRole: [Role.Write],
    },
  },
];
