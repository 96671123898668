import AsyncLock from 'async-lock';
import { Inject } from 'inversify-props';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { SearchResponse, SearchPageMetaData, SearchQueryData } from '@/utility/definitions';
import { Contact } from '@govflanders/mbp-admin-panel-shared';
import { CONTACTS_SERVICE, ContactsService } from '@/services/contacts';

@Module({ namespaced: true })
export class ContactSearchModule extends VuexModule {
  public response: SearchResponse<Contact> | null = null;
  public error: Error | null = null;

  /**
   * Internal async lock used to prevent multiple search operations from
   * conflicting.
   *
   * @var {AsyncLock}
   */
  private _lock = new AsyncLock();

  @Inject(CONTACTS_SERVICE)
  private contactsService!: ContactsService;

  public get isFailed(): boolean {
    return this.error !== null;
  }

  public get isSuccess(): boolean {
    return !this.isFailed && !this.isLoading;
  }

  public get isLoading(): boolean {
    return !this.isFailed && this.response === null;
  }

  public get results(): Contact[] {
    if (this.response && this.isSuccess) {
      return this.response.content;
    }

    return [];
  }

  public get pageMetaData(): SearchPageMetaData | null {
    if (this.response && this.isSuccess) {
      return this.response.pageMetadata;
    }

    return null;
  }

  @Mutation
  public setLoading(): void {
    this.response = null;
    this.error = null;
  }

  @Mutation
  public setSuccess(searchResponse: SearchResponse<Contact>): void {
    this.response = searchResponse;
    this.error = null;
  }

  @Mutation
  public setFailed(error: Error): void {
    this.error = error;
    this.response = null;
  }

  @Action
  public async search(queryData: SearchQueryData): Promise<Contact[]> {
    // Do not allow parallel execution to prevent conflicting states to be resolved or
    // the results to get out of sync with the query.
    return new Promise((resolve, reject) =>
      this._lock.acquire('execute', async () => {
        this.context.commit('setLoading');
        await this.contactsService
          .find(queryData)
          .then(response => {
            this.context.commit('setSuccess', response);
            resolve(response.content);
          })
          .catch(error => {
            this.context.commit('setFailed', error);
            reject();
          });
      }),
    );
  }
}
