import { Inject } from 'inversify-props';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { Contact } from '@govflanders/mbp-admin-panel-shared';
import { CONTACTS_SERVICE, ContactsService } from '@/services/contacts';

@Module({ namespaced: true })
export class ContactModule extends VuexModule {
  @Inject(CONTACTS_SERVICE)
  private contactService!: ContactsService;

  @Action
  public async create(contactConfig: Contact): Promise<Contact> {
    return this.contactService.create(contactConfig);
  }

  @Action
  public async get(id: string): Promise<Contact> {
    return this.contactService.get(id);
  }

  @Action
  public async update(contactConfig: Contact): Promise<Contact> {
    return this.contactService.update(contactConfig);
  }

  @Action
  public async delete(id: string): Promise<boolean> {
    return this.contactService.delete(id);
  }
}
