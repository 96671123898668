import { Inject } from 'inversify-props';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { MBPApp } from '@govflanders/mbp-admin-panel-shared';
import { MBPAPP_SERVICE, MBPAppService } from '@/services/mbp-app';

@Module({ namespaced: true })
export class MBPAppModule extends VuexModule {
  @Inject(MBPAPP_SERVICE)
  private mbpAppService!: MBPAppService;

  @Action
  public async create(contactConfig: MBPApp): Promise<MBPApp> {
    return this.mbpAppService.create(contactConfig);
  }

  @Action
  public async get(id: string): Promise<MBPApp> {
    return this.mbpAppService.get(id);
  }

  @Action
  public async update(contactConfig: MBPApp): Promise<MBPApp> {
    return this.mbpAppService.update(contactConfig);
  }

  @Action
  public async delete(id: string): Promise<boolean> {
    return this.mbpAppService.delete(id);
  }
}
