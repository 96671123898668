import { Inject } from 'inversify-props';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { Branding } from '@govflanders/mbp-admin-panel-shared';
import { BRANDING_SERVICE, BrandingService } from '@/services/branding';

@Module({ namespaced: true })
export class BrandingModule extends VuexModule {
  @Inject(BRANDING_SERVICE)
  private brandingService!: BrandingService;

  @Action
  public async create(contactConfig: Branding): Promise<Branding> {
    return this.brandingService.create(contactConfig);
  }

  @Action
  public async get(id: string): Promise<Branding> {
    return this.brandingService.get(id);
  }

  @Action
  public async update(contactConfig: Branding): Promise<Branding> {
    return this.brandingService.update(contactConfig);
  }

  @Action
  public async delete(id: string): Promise<boolean> {
    return this.brandingService.delete(id);
  }
}
