import { ImportedWidget } from '@govflanders/mbp-admin-panel-shared';

/**
 * Holds the Widget Import Service name.
 *
 * @var {Symbol}
 */

export const WIDGET_IMPORT_SERVICE = Symbol.for('WIDGET_IMPORT_SERVICE');

/**
 * Interface which describes a Widget Config service.
 */
export interface WidgetImportService {
  /**
   * Get the widgets from a certain request with a certain request id.
   *
   * @param {string} requestId
   *   The request id in the CRM
   *
   * @return {Promise<ImportedWidget[]>}
   *   A promise to return a list of widgets that were requested
   */
  getWidgetsFromRequest(requestId: string): Promise<ImportedWidget[]>;
}
