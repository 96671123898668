import { NavigationGuardNext, Route } from 'vue-router';
import { get } from 'lodash';
import store from '@/store';

/**
 * Navigation guard which requires user to be authenticated if a route
 * is marked as non public.
 *
 * @param {Route} to
 *   Route to which navigation will be performed.
 * @param {Route} from
 *   Previously active route.
 * @param {NavigationGuardNext} next
 *   Function used resolve guard result.
 */
export async function authGuard(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
  try {
    if (get(to.meta, 'isPublic')) {
      next();
    } else {
      await store.dispatch('app/refresh');

      if (!store.getters['app/isAuthenticated']) {
        window.location.href = '/auth/openid-connect/login';
      }
    }
  } catch (error) {
    console.error('Authentication failed', error);
    next(error);
  }

  next();
}
