export const enum ConfigValueName {
  ENV = 'env',
  WIDGET_BASE_URL = 'widgetBaseUrl',
  CLOUDINARY_FILE_PREFIX = 'CLOUDINARY_FILE_PREFIX',
  CLOUDINARY_FILE_PREFIX_MBPAPP = 'CLOUDINARY_FILE_PREFIX_MBPAPP',
  APPSTORE_CONNECT_URL = 'appstoreConnectUrl',
  AASA_URL = 'aasaUrl',
  ASSETLINKS_URL = 'assetLinksUrl',
  PLAYSTORE_URL = 'playstoreUrl',
}
