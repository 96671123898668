import { Inject } from 'inversify-props';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { WidgetImportService, WIDGET_IMPORT_SERVICE } from '@/services';
import { Widget } from '@govflanders/mbp-admin-panel-shared';

/**
 * Interface which describes the Widget Search Module state.
 */
export interface WidgetImportModuleState {
  /**
   * Holds the actual search response.
   *
   * @var {SearchResponse<Widget>|null}
   */
  _response: Widget[] | null;

  /**
   * Holds the error which caused a failed state.
   *
   * @var {Error|null}
   */
  _responseError: Error | null;
}

@Module({ namespaced: true })
export class WidgetImportModule extends VuexModule implements WidgetImportModuleState {
  /**
   * Holds the actual response.
   *
   * @var _response {Widget[] | null}
   */
  public _response: Widget[] | null = null;

  /**
   * Holds the error which caused a failed state.
   *
   * @var {Error|null}
   */
  public _responseError: Error | null = null;

  /**
   * Holds the widget import service.
   *
   * @var {WidgetImportService}
   */
  @Inject(WIDGET_IMPORT_SERVICE)
  private _widgetImportService!: WidgetImportService;

  /**
   * Get a value indicating whether get operation has failed.
   *
   * @return {boolean}
   *   True if search operation failed, otherwise false.
   */
  public get isFailed(): boolean {
    return this._responseError !== null;
  }

  /**
   * Get a value indicating whether get operation is successful.
   *
   * @return {boolean}
   *   True if search operation is successful, otherwise false.
   */
  public get isSuccess(): boolean {
    return !this.isFailed && !this.isLoading;
  }

  /**
   * Get a value indicating whether get operation is loading.
   *
   * @return {boolean}
   *   True if search operation is loading, otherwise false.
   */
  public get isLoading(): boolean {
    return !this.isFailed && this._response === null;
  }

  /**
   * Get the response results.
   *
   * @return {Widget[]|null}
   *   An array of Widget objects if the operation was successful, otherwise null.
   */
  public get results(): Widget[] | null {
    // Check whether search operation was successful.
    if (this._response && this.isSuccess) {
      // Use the results from the search response.
      return this._response;
    }

    return null;
  }

  /**
   * Transition to a loading state.
   */
  @Mutation
  public loading(): void {
    // Clear error first to prevent UI from showing error before
    // transition to loading state.
    this._response = null;
    this._responseError = null;
  }

  /**
   * Transition to a success state.
   *
   * @param {Widget[]} response
   *   Resolved search response.
   */
  @Mutation
  public success(response: Widget[]): void {
    this._response = response;
    this._responseError = null;
  }

  /**
   * Transition to a failed state.
   *
   * @param {Error} responseError
   *   Error which occurred during search operation.
   */
  @Mutation
  public failed(responseError: Error): void {
    this._responseError = responseError;
    this._response = null;
  }

  /**
   * Perform a widget get operation for given request id.
   *
   * @param {string} requestId
   *   An object which represents the id of the request for the widget set
   *
   * @return {Promise<void>}
   *   A promise to get the widget configs that belong to the request id on this environment
   */
  @Action
  public async execute(requestId: string): Promise<void> {
    this.context.commit('loading');
    // Request widget search results for given query.
    return this._widgetImportService
      .getWidgetsFromRequest(requestId)
      .then(response => {
        // Transition to a success state for given response.
        this.context.commit('success', response);
      })
      .catch(error => {
        // Transition to a failed state for given error.
        this.context.commit('failed', error);
      });
  }
}
