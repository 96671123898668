import { PropOptions } from 'vue';
import { isValidSearchFilterOptions } from './options';
import { SearchFilterTypes, isValidSearchFilterType } from './types';

/**
 * Interface which describes a search filter definition.
 */
export interface SearchFilterDefinition {
  /**
   * Search filter type that should be used.
   *
   * @var {SearchFilterTypes}
   */
  readonly type: SearchFilterTypes;

  /**
   * Name of the search query property which should be used.
   *
   * @var {string}
   */
  readonly name: string;

  /**
   * Label for the search filter.
   *
   * @var {string}
   */
  readonly label: string;

  /**
   * Support additional search filter definition options. Usage depends
   * on the search filter type.
   *
   * @see isValidSearchFilterDefinition
   */
  readonly [key: string]: any;
}

/**
 * Type alias for a list of search filter definitions.
 */
export type SearchFilterDefinitions = SearchFilterDefinition[];

/**
 * Validate whether given search filter definition is valid.
 *
 * @param {SearchFilterDefinition} filter
 *   An object which represents a search filter definition.
 *
 * @return {boolean}
 *   True if valid, otherwise false.
 */
export function isValidSearchFilterDefinition(filter: SearchFilterDefinition): boolean {
  // Validate whether filter argument matches basic requirements.
  let valid =
    filter !== null &&
    typeof filter === 'object' &&
    typeof filter.name === 'string' &&
    typeof filter.label === 'string' &&
    isValidSearchFilterType(filter.type);

  if (valid) {
    // Perform additional validation based on search filter type.
    switch (filter.type) {
      case SearchFilterTypes.Radio:
        // Validate whether search filter options are valid.
        valid = isValidSearchFilterOptions(filter.options);
        break;
    }
  }

  return valid;
}

/**
 * Validate whether given search filter definitions are valid.
 *
 * @param {SearchFilterDefinitions} filters
 *   A list of search filter definitions.
 *
 * @return {boolean}
 *   True if valid, otherwise false.
 */
export function isValidSearchFilterDefinitions(filters: SearchFilterDefinitions): boolean {
  // Validate value is an array as required by the specification.
  if (Array.isArray(filters)) {
    // Initialize variable to true as default validation behavior.
    let valid = true;
    // Iterate through the search filter definitions.
    for (const filter of filters) {
      // Validate the given search filter definition.
      if (!isValidSearchFilterDefinition(filter)) {
        // Mark search filter definitions as invalid.
        valid = false;
        // Stop validating search filter definitions on first failure.
        break;
      }
    }

    return valid;
  }

  return false;
}

/**
 * Component property options for SearchFilterDefinitions.
 *
 * @var {PropOptions<SearchFilterDefinitions>}
 */
export const SEARCH_FILTER_DEFINITION_PROP_OPTIONS = {
  type: Object,
  validator: isValidSearchFilterDefinition,
} as PropOptions<SearchFilterDefinition>;

/**
 * Component property options for SearchFilterDefinitions.
 *
 * @var {PropOptions<SearchFilterDefinitions>}
 */
export const SEARCH_FILTER_DEFINITIONS_PROP_OPTIONS = {
  type: Array,
  validator: isValidSearchFilterDefinitions,
} as PropOptions<SearchFilterDefinitions>;
