import { PropOptions } from 'vue';

/**
 * Type alias for a search filter option value.
 */
export type SearchFilterOptionValue = any;

/**
 * Interface which describes a search filter option.
 */
export interface SearchFilterOption {
  /**
   * Human readable name of the option.
   *
   * @var {string}
   */
  readonly label: string;

  /**
   * Value associated with the option.
   *
   * @var {SearchFilterOptionValue}
   */
  readonly value: SearchFilterOptionValue;
}

/**
 * Type alias for a list of search filter options.
 */
export type SearchFilterOptions = SearchFilterOption[];

/**
 * Validate whether given search filter option is valid.
 *
 * @param {SearchFilterOption} option
 *   An object which represents a search filter option.
 *
 * @return {boolean}
 *   True if valid, otherwise false.
 */
export function isValidSearchFilterOption(option: SearchFilterOption): boolean {
  return (
    option !== null &&
    typeof option === 'object' &&
    typeof option.label === 'string' &&
    Object.prototype.hasOwnProperty.call(option, 'value')
  );
}

/**
 * Validate whether given search filter options are valid.
 *
 * @param {SearchFilterOptions} options
 *   A list of search filter options.
 *
 * @return {boolean}
 *   True if valid, otherwise false.
 */
export function isValidSearchFilterOptions(options: SearchFilterOptions): boolean {
  // Validate the options property is acording to specifications.
  if (Array.isArray(options)) {
    // Initialize variable to true as default validation behavior.
    let valid = true;
    // Iterat through the available options.
    for (const option of options) {
      // Validate whether given option is valid.
      if (!isValidSearchFilterOption(option)) {
        // Mark options definition as invalid.
        valid = false;
        // Stop validating options definition on first failure.
        break;
      }
    }

    return valid;
  }

  return false;
}

/**
 * Component property options for SearchFilterOptions.
 *
 * @var {PropOptions<SearchFilterDefinitionOptions>}
 */
export const SEARCH_FILTER_OPTION_PROP_OPTIONS = {
  type: Object,
  validator: isValidSearchFilterOption,
} as PropOptions<SearchFilterOption>;

/**
 * Component property options for SearchFilterOptions.
 *
 * @var {PropOptions<SearchFilterDefinitionOptions>}
 */
export const SEARCH_FILTER_OPTIONS_PROP_OPTIONS = {
  type: Array,
  validator: isValidSearchFilterOptions,
} as PropOptions<SearchFilterOptions>;
