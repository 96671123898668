import { Inject, injectable } from 'inversify-props';
import { HttpClientService, HTTP_CLIENT_SERVICE } from '../http-client';
import { IpdcProductTheme } from '@govflanders/mbp-admin-panel-shared';
import { SearchQueryData, SearchResponse } from '@/utility/definitions';
import { DEFAULT_PAGING_SIZE } from '@/constants';

@injectable()
export class IpdcProductThemeService {
  private static readonly BASE_URL: string = '/api/v1/ipdc/themes';

  public constructor(@Inject(HTTP_CLIENT_SERVICE) private _httpClient: HttpClientService) {}

  public async search(queryData: SearchQueryData = {}): Promise<SearchResponse<IpdcProductTheme>> {
    const url = new URL(IpdcProductThemeService.BASE_URL, window.location.origin);
    url.searchParams.set('query', queryData.query || '');
    url.searchParams.set('offset', queryData.offset || 0);
    url.searchParams.set('limit', queryData.limit || DEFAULT_PAGING_SIZE);

    // Resolve search response data.
    return this._httpClient
      .get(url.toString(), {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(response => response.json());
  }

  public create(ipdcProductTheme: IpdcProductTheme): Promise<IpdcProductTheme> {
    return this._httpClient
      .post(IpdcProductThemeService.BASE_URL, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ipdcProductTheme),
      })
      .then(response => response.json());
  }

  public get(id: string): Promise<IpdcProductTheme> {
    return this._httpClient
      .get(`${IpdcProductThemeService.BASE_URL}/${id}`, {
        headers: {
          Accept: 'application/json',
        },
      })
      .then(response => response.json());
  }

  public update(ipdcProductTheme: IpdcProductTheme): Promise<IpdcProductTheme> {
    return this._httpClient
      .put(`${IpdcProductThemeService.BASE_URL}/${ipdcProductTheme.code}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(ipdcProductTheme),
      })
      .then(response => response.json());
  }

  public bulkUpdate(ipdcProductThemes: IpdcProductTheme[]): Promise<IpdcProductTheme[]> {
    return Promise.all(ipdcProductThemes.map(theme => this.update(theme)));
  }

  public delete(id: string): Promise<boolean> {
    return this._httpClient
      .delete(`${IpdcProductThemeService.BASE_URL}/${id}`)
      .then(response => response.json());
  }
}
