import { Inject } from 'inversify-props';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import {
  SearchFilterDefinitions,
  SearchFilterTypes,
  SearchResponse,
  SearchPageMetaData,
  SearchQueryData,
} from '@/utility/definitions';
import { IpdcProductTheme } from '@govflanders/mbp-admin-panel-shared';
import { IPDC_PRODUCT_THEME_SERVICE, IpdcProductThemeService } from '@/services/ipdc';

@Module({ namespaced: true })
export class IpdcProductThemeSearchModule extends VuexModule {
  public _searchResponse: SearchResponse<IpdcProductTheme> | null = null;
  public _searchResponseError: Error | null = null;

  @Inject(IPDC_PRODUCT_THEME_SERVICE)
  private _ipdcProductThemeService!: IpdcProductThemeService;

  public get isFailed(): boolean {
    return this._searchResponseError !== null;
  }

  public get isSuccess(): boolean {
    return !this.isFailed && !this.isLoading;
  }

  public get isLoading(): boolean {
    return !this.isFailed && this._searchResponse === null;
  }

  public get results(): IpdcProductTheme[] | null {
    if (this._searchResponse && this.isSuccess) {
      return this._searchResponse.items;
    }

    return null;
  }

  public get pageMetaData(): SearchPageMetaData | null {
    if (this._searchResponse && this.isSuccess) {
      return this._searchResponse.pageMetadata;
    }

    return null;
  }

  public get filters(): SearchFilterDefinitions {
    return [
      {
        type: SearchFilterTypes.Input,
        name: 'query',
        label: 'external-apps.search.filters.query.label',
      },
    ];
  }

  @Mutation
  public loading(): void {
    this._searchResponse = null;
    this._searchResponseError = null;
  }

  @Mutation
  public success(searchResponse: SearchResponse<IpdcProductTheme>): void {
    this._searchResponse = searchResponse;
    this._searchResponseError = null;
  }

  @Mutation
  public failed(searchResponseError: Error): void {
    this._searchResponseError = searchResponseError;
    this._searchResponse = null;
  }

  @Action
  public async execute(queryData: SearchQueryData): Promise<void> {
    this.context.commit('loading');
    await this._ipdcProductThemeService
      .search(queryData)
      .then(response => {
        this.context.commit('success', response);
      })
      .catch(error => {
        this.context.commit('failed', error);
      });
  }
}
