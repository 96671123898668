import { FeatureToggleName } from '@govflanders/mbp-admin-panel-shared';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export class FeatureTogglesModule extends VuexModule {
  private _featureToggles: Record<FeatureToggleName, boolean> | undefined;

  public get isFeatureAvailable(): (FeatureToggleName) => boolean {
    return (featureToggleName: FeatureToggleName) => {
      return this._featureToggles && !!this._featureToggles[featureToggleName];
    };
  }

  @Mutation
  public setFeatureToggles(featureToggles: Record<FeatureToggleName, boolean> | undefined): void {
    this._featureToggles = featureToggles;
  }

  @Action
  public init(): void {
    try {
      const config: { featureToggles: Record<FeatureToggleName, boolean> } = (window as any)
        .__CONFIG__;

      if (config) {
        this.context.commit('setFeatureToggles', config.featureToggles);
      } else {
        console.error('No config found, aborting feature toggle init');
      }
    } catch (e) {
      console.error(e);
    }
  }
}
