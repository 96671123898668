export interface Contact {
  key: string;
  name?: string;
  about?: string;
  ouapi_id?: string;
  configCategory?: 'CONTACTOPTIES';
  createdAt?: string;
  lastModifiedAt?: string;
  channels?: ContactChannelOption[];
  social?: {
    description?: string;
    media?: {
      icon?: string;
      text?: string;
      description?: string;
      url?: string;
    }[];
  };
  subject?: string;
  requestedBy?: string;
  privacy?: ContactPrivacy;
}

export interface ContactPrivacy {
  gdpr: string;
}

export interface ContactChannelOption {
  id?: string;
  name?: string;
  icon?: string;
  type?: string;
  url?: string;
  information?: string;
  ouapi_contactline_id?: string;
  value?:
    | null
    | {
        name: string;
        address: string;
      }
    | {
        campaign: string;
        workgroup: string;
      }
    | {
        campaign: string;
        information: string;
        fields: {
          type: string;
          label: string;
          attrs: {
            name: string;
            maxlength: number;
            type: string;
            placeholder: string;
            required: string;
          };
        }[];
      };
  availability?: {
    openInformation?: string;
    closedInformation?: string;
    exceptionallyClosedInformation?: string;
    hours?: ContactOptionTimeSlot[][];
    exceptions?: ContactOptionAvailabilityException[];
    holidays?: ContactOptionAvailabilityHoliday[];
    remark: string;
  };
}

export enum ContactOptionTimeSlotRemark {
  OPEN = 'OPEN',
  APPOINTMENT = 'APPOINTMENT',
  PHONE = 'PHONE',
  DIGITAL = 'DIGITAL',
  OTHER = 'OTHER',
}

export interface ContactOptionTimeSlot {
  from: string;
  to: string;
  remark?: ContactOptionTimeSlotRemark;
  customRemark?: string;
}

export interface ContactOptionAvailabilityException {
  date?: string;
  information?: string;
  hours?: ContactOptionTimeSlot[];
}

export const Holidays = [
  'NIEUWJAAR',
  'PAASMAANDAG',
  'DAG_VAN_DE_ARBEID',
  'O_H_HEMELVAART',
  'PINKSTERMAANDAG',
  'FEEST_VAN_DE_VLAAMSE_GEMEENSCHAP',
  'NATIONALE_FEESTDAG',
  'O_L_V_HEMELVAART',
  'ALLERHEILIGEN',
  'ALLERZIELEN',
  'WAPENSTILSTAND',
  'FEEST_VAN_DE_DYNASTIE',
  'KERSTMIS',
  '2DE_KERSTDAG',
] as const;

export type ContactOptionAvailabilityHoliday = typeof Holidays[number];

export interface ContactOptionAvailabilityHolidayWithHours {
  name: ContactOptionAvailabilityHoliday;
  hours?: ContactOptionTimeSlot[];
}
