import { Role } from '@govflanders/mbp-admin-panel-shared';
import { RouteConfig } from 'vue-router';

/**
 * List of Widget related route configurations.
 *
 * @var {RouteConfig[]}
 */
export const IPDC_ROUTES: RouteConfig[] = [
  {
    path: '/ipdc/products',
    name: 'ipdc.product',
    component: () =>
      import(/* webpackChunkName: "ipdc" */ '@/views/ipdc/products/IpdcProductSearch.vue'),
    meta: {
      requireRole: [Role.Write],
    },
  },
  {
    path: '/ipdc/products/:id/edit',
    name: 'ipdc.product.edit',
    component: () =>
      import(/* webpackChunkName: "ipdc" */ '@/views/ipdc/products/IpdcProductEdit.vue'),
    props: true,
    meta: {
      requireRole: [Role.Write],
    },
  },
  {
    path: '/ipdc/themes',
    name: 'ipdc.theme',
    component: () =>
      import(/* webpackChunkName: "ipdc" */ '@/views/ipdc/themes/IpdcProductThemeSearch.vue'),
    meta: {
      requireRole: [Role.Write],
    },
  },
  {
    path: '/ipdc/themes/add',
    name: 'ipdc.theme.add',
    component: () =>
      import(/* webpackChunkName: "widget" */ '@/views/ipdc/themes/IpdcProductThemeAdd.vue'),
    meta: {
      requireRole: [Role.Write],
    },
  },
  {
    path: '/ipdc/themes/:id/edit',
    name: 'ipdc.theme.edit',
    component: () =>
      import(
        /* webpackChunkName: "ipdcProductThemes" */ '@/views/ipdc/themes/IpdcProductThemeEdit.vue'
      ),
    props: true,
    meta: {
      requireRole: [Role.Read],
    },
  },
  {
    path: '/ipdc/themes/:id/delete',
    name: 'ipdc.theme.delete',
    component: () =>
      import(/* webpackChunkName: "widget" */ '@/views/ipdc/themes/IpdcProductThemeDelete.vue'),
    props: true,
    meta: {
      requireRole: [Role.Write],
    },
  },
  {
    path: '/ipdc/themes/sort',
    name: 'ipdc.theme.sort',
    component: () => import('@/views/ipdc/themes/IpdcProductThemeSort.vue'),
    props: true,
    meta: {
      requireRole: [Role.Write],
    },
  },
];
