import { ContactPreference } from '@govflanders/mbp-admin-panel-shared';
import { Inject, injectable } from 'inversify-props';
import { HTTP_CLIENT_SERVICE, HttpClientService } from '../http-client';

@injectable()
export class ContactPreferenceService {
  private BASE_URL = '/api/v1/contact-preference';

  public constructor(@Inject(HTTP_CLIENT_SERVICE) private _httpClient: HttpClientService) {}

  public search(email: string): Promise<ContactPreference[]> {
    return this._httpClient.get(this.getUrl(email)).then(response => response.json());
  }

  public delete(email: string): Promise<boolean> {
    return this._httpClient.delete(this.getUrl(email)).then(response => response.json());
  }

  private getUrl(email: string): string {
    const url = new URL(this.BASE_URL, window.location.origin);
    if (email) {
      url.searchParams.set('email', email);
    }
    return url.toString();
  }
}
