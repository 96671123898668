import { Role } from '@govflanders/mbp-admin-panel-shared';
import { RouteConfig } from 'vue-router';

/**
 * List of Contact related route configurations.
 *
 * @var {RouteConfig[]}
 */
export const CONTACT_ROUTES: RouteConfig[] = [
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import(/* webpackChunkName: "contacts" */ '@/views/contact/ContactSearch.vue'),
    meta: {
      requireRole: [Role.Read],
    },
  },
  {
    path: '/contacts/add',
    name: 'contacts.add',
    component: () => import(/* webpackChunkName: "widget" */ '@/views/contact/ContactAdd.vue'),
    meta: {
      requireRole: [Role.Write],
    },
  },
  {
    path: '/contacts/:id/edit',
    name: 'contacts.edit',
    component: () => import(/* webpackChunkName: "contacts" */ '@/views/contact/ContactEdit.vue'),
    props: true,
    meta: {
      requireRole: [Role.Read],
    },
  },
  {
    path: '/contacts/:id/delete',
    name: 'contacts.delete',
    component: () => import(/* webpackChunkName: "widget" */ '@/views/contact/ContactDelete.vue'),
    props: true,
    meta: {
      requireRole: [Role.Write],
    },
  },
];
